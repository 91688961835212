
import { mapWritableState } from 'pinia'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '@/stores/market'
import { useCustomer } from '@/stores/customer'
import { ref, useContext } from '@nuxtjs/composition-api'
import { setUrl } from '@/utilities'

export default {
  name: 'MPPopularBrands',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const dcmStore = useDcmStore()
    const { $device } = useContext()

    let slidePerPage = 0
    let keenSliderOptions = {}
    if ($device && $device.isMobile && !$device.isCrawler) {
      slidePerPage = 2
      keenSliderOptions = {
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    } else if ($device && $device.isTablet && !$device.isCrawler) {
      slidePerPage = 3
      keenSliderOptions = {
        rubberband: false,
        breakpoints: {
          '(max-width: 768px)': {
            slides: { perView: 2, spacing: 8 }, // Up to 768px
          },
          '(min-width: 769px) and (max-width: 1000px)': {
            slides: { perView: slidePerPage, spacing: 8 }, // From 769px to 1000px
          },
          '(min-width: 1001px)': {
            slides: { perView: slidePerPage + 1, spacing: 8 }, // Greater than 1000px
          },
        },
      }
    } else if ($device && $device.isDesktop && !$device.isCrawler) {
      slidePerPage = 5

      keenSliderOptions = {
        breakpoints: {
          '(orientation: portrait)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(orientation: portrait) and (min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape) and (min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    }
    return {
      marketStore,
      keenSliderOptions,

      slidePerPage,
      customerStore,
      dcmStore,
    }
  },
  data() {
    return {
      waitingToLoad: true,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.startLoadingPopularBrands()
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['popular_brands']),
    isShowingPopularBrands() {
      return this.popular_brands?.items
        .filter(item => {
          return !item.is_hidden
        })
        .sort((a, b) => a.seq_no - b.seq_no)
    },
    showComponent() {
      return this.popular_brands.items.length && !this.popular_brands?.is_hidden
    },
    isClickableBlock() {
      return this.popular_brands?.is_clickable
    },
  },
  watch: {
    showComponent(val) {
      this.$nextTick(() => {
        if (val) {
          setTimeout(() => {
            this.$refs?.MPPopularBrandsSlider?.updateSlider()
          }, 100)
        }
      })
    },
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.landingPopularBrands,
        this.startLoadingPopularBrands,
      )
    }
  },
  methods: {
    handleBrandClick(brand) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'homepage_popular_brands',
          creative_url: brand?.action_url,
          publish_date: brand?.active_from,
          unpublish_date: brand?.active_to,
          location_code: this.popular_brands?.location_code,
          object: 'card',
          creative_id: brand?.id,
          brand_name: brand?.brand_name,
          category_ids: [brand?.category_id],
        })
      }
      if (brand.action_url) {
        window.location.href = brand.action_url
      }
    },
    async startLoadingPopularBrands() {
      await this.dcmStore.getPopularBrands()
      if (this.showComponent) {
        this.waitingToLoad = false
      }
    },
  },
}
